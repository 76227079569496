import axios from 'axios';
import { useState } from 'react';
import useSelector from '../redux/typedHooks';

interface Props {
  request: {
    customerId: string;
    phoneNumber: string;
  };
}

interface ReturnType {
  sendSmsCode: ({ request }: Props) => void;
  isLoading: boolean;
  orchestrationId?: string;
  error: Error | null;
}

export const useSendSmsCode = (): ReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [orchestrationId, setOrchestrationId] = useState<string>();
  const [error, setError] = useState<Error | null>(null);
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = useSelector((state) => state.application);

  const sendSmsCode = async ({ request }: Props) => {
    if (request && !isLoading) {
      setIsLoading(true);
      try {
        const res = await axios.post<string>(`${apimBaseUrl}/${apimMembershipApi}/start-challenge`, request, {
          headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
        });
        setIsLoading(false);
        setOrchestrationId(res.data);
        setError(null);
      } catch (e: unknown) {
        setIsLoading(false);
        setOrchestrationId(undefined);
        setError(e as Error);
      }
    }
  };

  return { sendSmsCode, isLoading, orchestrationId, error };
};
