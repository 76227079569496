import axios from 'axios';
import { useState } from 'react';
import useSelector from '../redux/typedHooks';

interface Props {
  request: {
    email: string;
    phoneNumber: string;
    customerId: string;
    orchestrationId: string;
    validationCode: string;
  };
}

interface ReturnType {
  addEmail: ({ request }: Props) => void;
  isLoading: boolean;
  isSubmitted: boolean;
  errorMessage: string | null;
}

export const useAddEmail = (): ReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { apimBaseUrl, apimContentHub, apimMembershipApi } = useSelector((state) => state.application);

  const createErrorMessage = (data: string, status: number) => {
    if (status === 400 && data === 'Email already in use by an other customer.') {
      return 'Denne e-posten har vi sett før. Er du sikker på at du har skrevet riktig?';
    }
    return 'Oops, noe gikk galt. Vi klarte ikke å lagre e-posten.';
  };

  const addEmail = async ({ request }: Props) => {
    if (request && !isLoading) {
      setIsLoading(true);
      try {
        await axios.post(`${apimBaseUrl}/${apimMembershipApi}/addemail`, request, {
          headers: { 'Ocp-Apim-Subscription-Key': apimContentHub },
        });
        setIsLoading(false);
        setIsSubmitted(true);
        setErrorMessage(null);
      } catch (e: any) {
        setErrorMessage(createErrorMessage(e.response.data, e.response.status));
        setIsLoading(false);
        setIsSubmitted(false);
      }
    }
  };

  return { addEmail, isLoading, isSubmitted, errorMessage };
};
