import React, { useEffect, useState, useContext, KeyboardEvent } from 'react';
import { Input, Label } from '@naf/input';
import { Button } from '@naf/button';
import { UserInteractionVariant } from '@naf/user-interaction';
import { FormProps } from './SendCodeForm';
import { Steps, OrchestrationContext, CustomerEmailInfoType, SetContextType } from '../index';
import S from '../styles';
import { useValidateSmsCode } from '../../../hooks/useValidateSmsCode';

export const ValidateCodeForm = ({ setStep }: FormProps) => {
  const [code, setCode] = useState<string>();
  const { validateSmsCode, isLoading, isValidated, error } = useValidateSmsCode();
  const [customerInformationState, setCustomerInformationState] = useContext(OrchestrationContext);

  useEffect(() => {
    if (!isLoading && !error && isValidated) {
      setStep(Steps.RegisterEmail);
    }
  }, [isLoading, error, isValidated, setStep]);

  useEffect(() => {
    if (customerInformationState) {
      (setCustomerInformationState as SetContextType)(
        Object.assign(customerInformationState as CustomerEmailInfoType, { validationCode: code || '' }),
      );
    }
  }, [customerInformationState, code, setCustomerInformationState]);

  useEffect(() => {
    if (!(customerInformationState as CustomerEmailInfoType)?.customerId) {
      (setCustomerInformationState as SetContextType)(undefined);
      setStep(Steps.SendCode);
    }
  }, [customerInformationState, setCustomerInformationState, setStep]);

  const handleSubmit = () => {
    if (code && (customerInformationState as CustomerEmailInfoType)?.orchestrationId) {
      const request = {
        orchestrationId: (customerInformationState as CustomerEmailInfoType).orchestrationId || '',
        challengeCode: parseInt(code, 10),
      };
      validateSmsCode({ request });
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleGoBack = () => {
    (setCustomerInformationState as SetContextType)(undefined);
    setStep(Steps.SendCode);
  };

  return (
    <S.Wrapper>
      <S.Feedback variant={UserInteractionVariant.Info} title="">
        Hvis vi har registrert telefonnummeret ditt hos oss, vil du få en kode på sms.
      </S.Feedback>
      <Label>{`Skriv inn koden fra sms'en`}</Label>
      <Input type="text" value={code} onChange={(value: string) => setCode(value)} onKeyUp={handleKeyUp} />
      <Button type="button" onClick={handleGoBack} variant="outline">
        Tilbake
      </Button>
      <Button type="button" onClick={() => handleSubmit()} isLoading={isLoading}>
        Send inn
      </Button>
      {error && !isValidated && (
        <S.Feedback variant={UserInteractionVariant.Error} title="">
          Oops, noe gikk galt. Sjekk om du har skrevet riktig kode.
        </S.Feedback>
      )}
    </S.Wrapper>
  );
};
