import React, { useEffect, useState, createContext, useMemo, ReactElement } from 'react';
import { Grid, GridCol } from '@naf/grid';
import { Text, TextVariant } from '@naf/text';
import { TextListItem } from '@naf/text-list';

import { LayoutWithMainContent } from '../../components/layout/Layout';
import { DEFAULT_IMG_URL } from '../../lib/constants';
import S from './styles';
import { SendCodeForm } from './addEmailComponents/SendCodeForm';
import { ValidateCodeForm } from './addEmailComponents/ValidateCodeForm';
import { RegisterEmailForm } from './addEmailComponents/RegisterEmailForm';
import { useSessionStorage } from '../../hooks/useSessionStorage';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';

export const path = '/registrer-epost';

export enum Steps {
  SendCode = 'Send code',
  ValidateCode = 'Validate code',
  RegisterEmail = 'Register Email',
}

export interface CustomerEmailInfoType {
  orchestrationId?: string;
  phoneNumber?: string;
  customerId?: string;
  validationCode?: string;
}

export type SetContextType = React.Dispatch<React.SetStateAction<CustomerEmailInfoType | undefined>>;

export const OrchestrationContext = createContext<(CustomerEmailInfoType | SetContextType | undefined)[]>([undefined]);

export const EmailAcquisition = () => {
  const [step, setStep] = useSessionStorage('stepState', Steps.SendCode);
  const [formState, setFormState] = useState<ReactElement | null>(null);
  const [customerInformationState, setCustomerInformationState] = useState<CustomerEmailInfoType>();
  const value = useMemo(() => [customerInformationState, setCustomerInformationState], [customerInformationState]);
  const TextListItems = [
    'Be om å få tilsendt kode',
    'Legg inn koden du får på SMS',
    'Fyll ut e-postadressen din',
    'Lagre',
    'E-postadressen din er nå lagret og du har tilgang til å opprette bruker på Mitt NAF og i NAF-appen.',
    'Dersom du har gitt samtykker vil du heretter motta våre nyhetsbrev (du kan se og endre samtykker på Mitt NAF eller i NAF-appen).',
  ];

  useEffect(() => {
    const actionSwitch = () => {
      switch (step) {
        case Steps.SendCode:
          return <SendCodeForm setStep={setStep} />;
        case Steps.ValidateCode:
          return <ValidateCodeForm setStep={setStep} />;
        case Steps.RegisterEmail:
          return <RegisterEmailForm setStep={setStep} />;
        default:
          return null;
      }
    };
    setFormState(actionSwitch());
  }, [step, setStep]);

  return (
    <LayoutWithMainContent
      title="Registrer e-post"
      description=""
      imgUrl={DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'Registrer e-post',
        pageCategory: 'Registrer e-post',
        contentId: '',
      }}
      isHiddenFromSearch
      internalSearchMetaData={{ cardType: InternalSearchCardType.Simple }}
    >
      <Grid>
        <GridCol s="12" m="12" l="8" xl="8">
          <Text variant={TextVariant.Header1}>Registrer e-postadressen din her.</Text>
          <Text>Slik gjør du:</Text>
          <S.StyledTextList variant="number">
            {TextListItems.map((item) => (
              <TextListItem key={item}>{item}</TextListItem>
            ))}
          </S.StyledTextList>
          <OrchestrationContext.Provider value={value}>{formState}</OrchestrationContext.Provider>
        </GridCol>
      </Grid>
    </LayoutWithMainContent>
  );
};
