import React, { useContext, useState, useEffect, useRef } from 'react';
import { Input, Label } from '@naf/input';
import { Button } from '@naf/button';
import { UserInteractionVariant } from '@naf/user-interaction';
import S from '../styles';
import { CustomerEmailInfoType, OrchestrationContext, SetContextType, Steps } from '../index';
import { FormProps } from './SendCodeForm';
import { useAddEmail } from '../../../hooks/useAddEmail';

export const RegisterEmailForm = ({ setStep }: FormProps) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [validateEmail, setValidateEmail] = useState<string>();
  const { addEmail, isLoading, isSubmitted, errorMessage } = useAddEmail();
  const [customerInformationState, setCustomerInformationState] = useContext(OrchestrationContext);
  const validateRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!email && !validateEmail) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [email, validateEmail]);

  useEffect(() => {
    if (!(customerInformationState as CustomerEmailInfoType)?.customerId) {
      (setCustomerInformationState as SetContextType)(undefined);
      setStep(Steps.SendCode);
    }
  }, [customerInformationState, setCustomerInformationState, setStep]);

  const handleSubmit = () => {
    if (email && email !== validateEmail) {
      setIsValid(false);
      setIsDisabled(true);
    } else {
      setIsValid(true);
      const customerFromContext = customerInformationState as CustomerEmailInfoType;
      const request = {
        email: email || '',
        phoneNumber: customerFromContext?.phoneNumber || '',
        customerId: customerFromContext?.customerId || '',
        orchestrationId: customerFromContext?.orchestrationId || '',
        validationCode: customerFromContext?.validationCode || '',
      };
      addEmail({ request });
    }
  };

  const setNextFocus = (e: KeyboardEvent) => {
    if (e.key === 'Enter' && validateRef && validateRef.current) {
      validateRef.current.focus();
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <S.Wrapper>
      <Label>E-post</Label>
      <Input type="text" value={email} onChange={(value: string) => setEmail(value)} onKeyUp={setNextFocus} />
      <Label>Gjenta e-post</Label>
      <Input
        ref={validateRef}
        type="text"
        value={validateEmail}
        onChange={(value: string) => setValidateEmail(value)}
        onKeyUp={handleKeyUp}
      />
      {!isValid && <S.StyledMessage error>Du må skrive inn to like e-post adresser.</S.StyledMessage>}
      <Button type="button" onClick={() => setStep(Steps.ValidateCode)} variant="outline" disabled={isSubmitted}>
        Tilbake
      </Button>
      <Button type="button" onClick={() => handleSubmit()} disabled={isDisabled || isSubmitted} isLoading={isLoading}>
        Lagre e-post
      </Button>
      {isSubmitted && (
        <S.Feedback variant={UserInteractionVariant.Success} title="">
          {`Vi har lagret e-posten ${email}`}
        </S.Feedback>
      )}
      {errorMessage && !isSubmitted && (
        <S.Feedback variant={UserInteractionVariant.Error} title={errorMessage}>
          Står du fast? Ring 08 505 for å få hjelp til å registrere e-posten din.
        </S.Feedback>
      )}
    </S.Wrapper>
  );
};
