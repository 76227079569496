import React, { KeyboardEvent, useContext, useEffect, useState } from 'react';
import { Button } from '@naf/button';
import { Label, Phone } from '@naf/input';
import { UserInteractionVariant } from '@naf/user-interaction';
import { useSendSmsCode } from '../../../hooks/useSendSmsCode';
import { getURLParams } from '../../../lib/getUrlParams';
import { CustomerEmailInfoType, OrchestrationContext, SetContextType, Steps } from '../index';
import S from '../styles';

export interface FormProps {
  setStep: (arg: Steps) => void;
}

export const SendCodeForm = ({ setStep }: FormProps) => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [numberFromParams, setNumberFromParams] = useState<string>('');
  const [guid, setGuid] = useState<string>('');
  const [customerInformationState, setCustomerInformationState] = useContext(OrchestrationContext);

  const { sendSmsCode, isLoading, orchestrationId, error } = useSendSmsCode();

  // Fjerner overflødig '47' foran nummeret
  const trimPhoneNumber = (number: string): string => {
    if (number.length > 8 && (number.startsWith('47') || number.startsWith('0047') || number.startsWith('+47'))) {
      return number.slice(-8);
    }
    return number;
  };

  useEffect(() => {
    // Get id and number from url params on initial load
    const parsedParams = getURLParams();
    if (parsedParams.guid) setGuid(parsedParams.guid);
    if (parsedParams.phoneNumber) {
      const trimmedNumber = trimPhoneNumber(parsedParams.phoneNumber.trim());
      setNumberFromParams(trimmedNumber);
      setPhoneNumber(trimmedNumber);
    }
  }, []);

  useEffect(() => {
    (setCustomerInformationState as SetContextType)({ orchestrationId, phoneNumber, customerId: guid });
  }, [orchestrationId, phoneNumber, guid, setCustomerInformationState]);

  useEffect(() => {
    // If action is successful, send user to next step
    if (!isLoading && !error && (customerInformationState as CustomerEmailInfoType)?.orchestrationId) {
      setStep(Steps.ValidateCode);
    }
  }, [setStep, isLoading, customerInformationState, error]);

  const handleSubmit = () => {
    const trimmedPhoneNumber = trimPhoneNumber(phoneNumber.trim());
    const request = {
      customerId: guid,
      phoneNumber: trimmedPhoneNumber,
    };
    sendSmsCode({ request });
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <S.Wrapper>
      <Label>Telefonnummer:</Label>
      <Phone
        pattern="/`[0-9]+$/"
        autoComplete="tel"
        countries={[{ name: 'Norge', code: '47' }]}
        selectedCountry={{ name: 'Norge', code: '47' }}
        onChange={(phone: Record<string, string>) => {
          setPhoneNumber(phone.number.trim());
        }}
        onKeyUp={handleKeyUp}
        value={numberFromParams}
      />
      <Button type="button" isLoading={isLoading} onClick={handleSubmit}>
        Send meg kode
      </Button>
      {error && !orchestrationId && (
        <S.Feedback variant={UserInteractionVariant.Error} title="">
          Oops, noe gikk galt. Sjekk om du har skrevet riktig telefonnummer.
        </S.Feedback>
      )}
    </S.Wrapper>
  );
};
