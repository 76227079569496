import styled from 'styled-components';
import { spacing } from '@naf/theme';
import { TextList } from '@naf/text-list';
import { UserInteraction } from '@naf/user-interaction';
import { Message } from '@naf/input';

const StyledTextList = styled(TextList)`
  margin-bottom: ${spacing.space32};
`;

const Wrapper = styled.div`
  label + div {
    margin-bottom: ${spacing.space32};
  }

  button:not(:first-of-type) {
    margin-left: ${spacing.space24};
  }
`;

const Feedback = styled(UserInteraction)`
  margin-bottom: ${spacing.space32};

  &:last-child {
    margin-top: ${spacing.space32};
  }
`;

const StyledMessage = styled(Message)`
  margin-top: -${spacing.space32};
  margin-bottom: ${spacing.space32};
`;

export default { StyledTextList, Wrapper, Feedback, StyledMessage };
